import TYPES from '@/types';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzRequestForMovementsToPolicyQuery,
} from '@/modules/my-investment/allianz/allianz-request-for-movments-to-policy/application/queries';
import GetPersonAsyncQuery
  from '@/modules/my-investment/person/application/queries/get-person-async-query';
import {
  UpdateAccountNumberStatusSubscription,
} from '@/modules/my-investment/allianz/allianz-request-for-movments-to-policy/application/subscriptions';

// Domain
import {
  AllianzAccountEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/allianz-account-entity';
import State from '@/modules/my-investment/allianz/allianz-account/domain/state/state';
import {
  UpdateAccountNumberStateManager,
} from '@/modules/my-investment/allianz/allianz-request-for-movments-to-policy/domain/state-manager/update-account-number-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class BankAccountDialogViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_REQUEST_FOR_MOVEMENTS_TO_POLICY_QUERY)
  private readonly search_request_for_movements!: SearchAllianzRequestForMovementsToPolicyQuery;

  @Inject(TYPES.UPDATE_ACCOUNT_NUMBER_STATUS_SUBSCRIPTION)
  readonly update_account_number_status_subscription!: UpdateAccountNumberStatusSubscription;

  @Inject(TYPES.UPDATE_ACCOUNT_NUMBER_STATE_MANAGER)
  private readonly update_account_number_state!: UpdateAccountNumberStateManager;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.GET_PERSON_ASYNC_QUERY)
  private readonly get_person_query!: GetPersonAsyncQuery;

  readonly i18n_namespace = 'components.allianz-dashboard.bank-account';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  is_loading = true;

  account_holder = '';

  bank_institution = '';

  allianz_account: AllianzAccountEntity = new State().account;

  change_bank_account = false;

  change_bank_account_success = false;

  bank_account_change_in_progress = false;

  show_change_bank_account_status = false;

  change_status = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  alt_icon_bank_account = this.translate('alt_icon_bank_account');

  get change_status_socket() {
    return this.update_account_number_state.state.update_account_number.status;
  }

  loadAllianzAccount = async () => {
    try {
      this.allianz_account = await this.get_allianz_account_query
        .execute({ customer_id: this.customer_id, reload: true });
      this.bank_institution = this.formatBankInstitution(this.allianz_account.bank);
      await this.loadRequestChangesToPolicy();
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_account'));
      }
    }
  }

  loadPersonalInformationData = async () => {
    try {
      const { name, last_name, second_last_name } = await this.get_person_query.execute();
      this.account_holder = `${name} ${last_name} ${second_last_name}`;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_personal_information'));
    }
  }

  loadRequestChangesToPolicy = async () => {
    try {
      const query_params = {
        allianz_account_id: this.allianz_account.id,
        request_type: 'CHANGE_OF_CLABE_BANKING_FOR_WITHDRAWALS',
        is_active: true,
      };
      const pending_request = await this.search_request_for_movements
        .execute(query_params);
      if (pending_request.length) {
        this.change_status = pending_request[0]
          .request_status;
        this.bank_account_change_in_progress = true;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_request_changes_to_policy'));
    }
  }

  formatBankInstitution = (bank_institution: string): string => {
    let formatted_bank_institution = bank_institution;
    const internal_key_position = bank_institution.indexOf('SW');
    const dom_position = bank_institution.indexOf('(DOM');
    if (internal_key_position >= 0) {
      formatted_bank_institution = bank_institution.substring(0, internal_key_position);
    }
    if (dom_position >= 0) {
      formatted_bank_institution = bank_institution.substring(0, dom_position);
    }
    return formatted_bank_institution.trim();
  }

  changeBankAccount = () => {
    this.change_bank_account = true;
  }

  showChangeBankAccountStatus = () => {
    this.show_change_bank_account_status = true;
  }

  connectSocket = () => {
    if (this.customer_id) {
      this.update_account_number_status_subscription.internalExecute(this.customer_id);
    }
  }

  handleChangeStatus = async (change_status_socket: string) => {
    if (change_status_socket === 'REQUEST_APPROVED') {
      await this.loadAllianzAccount();
      this.show_change_bank_account_status = false;
      this.bank_account_change_in_progress = false;
    } else {
      this.change_status = change_status_socket;
      this.showChangeBankAccountStatus();
    }
  }

  destroyed = () => {
    this.update_account_number_status_subscription.stop();
  }

  initialize = async () => {
    this.connectSocket();
    await this.loadAllianzAccount();
    await this.loadPersonalInformationData();
    this.is_loading = false;
  }
}
