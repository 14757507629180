


































































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';

import BankAccountDialogViewModel
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/bank-account-dialog-view-model';

@Component({
  name: 'BankAccountDialog',
  components: {
    ChangeBankAccountDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/change-bank-account/ChangeBankAccountDialog.vue'),
    ChangeBankAccountStatusDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/change-bank-account/ChangeBankAccountStatusDialog.vue'),
  },
})
export default class BankAccountDialog extends Vue {
  @PropSync('isOpen', {
    type: Boolean,
    required: true,
    default: false,
  })
  synced_is_open!: boolean;

  bank_account_view_model = Vue.observable(new BankAccountDialogViewModel());

  @Watch('bank_account_view_model.change_status_socket')
  async handleChangeStatus(change_status_socket: string) {
    if (change_status_socket) {
      await this.bank_account_view_model.handleChangeStatus(change_status_socket);
    }
  }

  close() {
    this.synced_is_open = false;
  }

  mounted() {
    this.bank_account_view_model.initialize();
  }

  destroyed() {
    this.bank_account_view_model.destroyed();
  }
}
